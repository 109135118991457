import { createContext, useContext } from 'react';

export interface AnalyticsEvent {
  event: string;
  payload?: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const sendAnalytics = (event: AnalyticsEvent): void => {
  throw new Error('sendAnalytics() not implemented');
};

const SendAnalyticsContext = createContext(sendAnalytics);

export const SendAnalyticsContextProvider = SendAnalyticsContext.Provider;

export const useSendAnalytics = () => useContext(SendAnalyticsContext);
